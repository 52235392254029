/* Zoom embed styles */

#meetingSDKElement {
    width: 100%;
    min-height: 90vh;
    height: 70%;
    background-color: black;
}

html ,body{
    /* overflow: scroll !important; */
    overflow-x: hidden !important;
    min-width: 0 !important;
    min-height: 200 !important;

}

#zmmtg-root{
    background-color: white;
    display: none;
    overflow: scroll;
}